/**
* Generated automatically at built-time (2024-12-10T09:51:39.259Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "3d-guide-outdoor-kitchen",templateKey: "sites/101-fae1871c-eb9b-45f4-a150-0daccfe226ef"};