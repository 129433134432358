/**
* Generated automatically at built-time (2024-12-10T09:51:39.410Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "felicia-test",templateKey: "sites/101-e1dcc4b0-c371-4323-93c4-6c8581f34331"};